export default {
	priority: {
		home: 'Home',
		product: 'Product',
		about: 'About Us',
		language: 'Language',
		reliablePartner: 'Your reliable partner',
		homeText1: 'All-in-one solution for your any personal or business transaction requirements',
		details: 'Details',
		personal: 'For Personal',
		business: 'For Business',

		homeText2: 'Looking forward to haveing you join us!',
		introduction: 'Introduction',
		contact: 'Contact Us',
		address: 'Address',
		started: 'Get Started',

		oproducts: 'Our Products',
		remittanceNeeds: 'for any remittance needs',
		productText1:
			'Make transfer transactions anywhere and anytime in minutes with flat fees. We provide world-class financial services that are intuitive, fast, easy to use, and affordable for everyone and businesses, regardless of type and size.',
		exchangeRate: 'Exchange Rate',
		amount: 'Amount',
		exchange: 'Exchange',
		rate: 'Rate',
		domesticTransfer: 'Domestic Transfer',
		sendMoney: 'Send money without worry',
		productText2: 'You can transfer to anyone in Indonesia anywhere and anytime without worry. The transaction can be finished in minutes.',
		remittance: 'Remittance Cross-Border',
		remittanceFP: 'Remittance to your family and partners',
		productText3: 'You can transfer to your family, friends and your business partners abroad safe and fast in full amount.',
		remittanceAaccounts: 'Remittance to one or many accounts',
		productText4: 'Funds disbursement to accommodate all your  business needs, both manual and automatic, to one or many accounts at once.',

		aboutText1: 'To build the future of Open Finance with cutting-edge technology and a mission to improve financial inclusion',
		aboutText2:
			'Priority Valasindo Remittance (PVR) is an online remittance service that helps you send money abroad from Indonesia in an easier, cheaper, and faster way. PVR aims to always provide the best service for our users to send money abroad from Indonesia. We designed our products and services based on Indonesian market to provide a seamless transaction experience for your customers.',
		waw: 'Who Are We？',
		licensedby: 'Licensed by',
		License: 'License No. 12/42/DASP/10',
		destination: 'Choose Destination',
		transactionEasier: 'Transaction Easier',
		aboutText3: 'Finish your remittance on your cellphone without extra offline steps. ',
		fasterSafer: 'Faster and Safer',
		aboutText4: 'Recipient can receive the money in minutes. Moeny will be  refunded if transaction fails risk.',
		competitivePricing: 'Competitive Pricing',
		aboutText5: 'Lower price and flat transfer fee, to save your money as much as possible with tailored financial solution.',
		customerService: '7*24 Hours Customer Service',
		aboutText6: 'We will support you anytime when you need us.',
	},
}
