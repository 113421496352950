<!--
 * @Author: caizhihao
 * @Date: 2023-08-07 17:40:32
 * @LastEditors: caizhihao 177745994@qq.com
 * @LastEditTime: 2023-08-09 14:51:11
 * @FilePath: \PriorityPay-web\src\components\web-footer.vue
 * @Description: 
 * 
-->
<template>
	<footer class="footer">
		<section class="footer_main">
			<div class="fm_top">
				<div class="fm_title">Priority Pay</div>
				<div class="fm_start">
					<p>{{ $t('priority.homeText2') }}</p>
					<div>{{ $t('priority.started') }}</div>
				</div>
			</div>
			<div class="fm_center">
				<div class="fm_reliable">{{ $t('priority.reliablePartner') }}</div>
				<div class="fm_tab">
					<div class="fm_div fm_product">
						<p>{{ $t('priority.product') }}</p>
						<p>{{ $t('priority.personal') }}</p>
						<p>{{ $t('priority.business') }}</p>
					</div>
					<div class="fm_div fm_about">
						<p>{{ $t('priority.about') }}</p>
						<p>{{ $t('priority.introduction') }}</p>
					</div>
					<div class="fm_div fm_contactt">
						<p>{{ $t('priority.contact') }}</p>
						<p>Email: info@prioritypay.id</p>
						<p>
							{{ $t('priority.address') }}: Tokopedia Care Tower (Ciputra International) Lt. 17, Unit 5. JI Lingkar Luar Barat No 101 Rawa Buaya, Cengkareng, Jakarta Barat
							117740
						</p>
					</div>
				</div>
			</div>
			<div class="fm_footer">@2023 PriorityPay - All rights reserved</div>
		</section>
	</footer>
</template>

<script setup></script>

<style lang="scss" scoped>
.footer {
	font-family: SF-Pro-Text-Regular;
	background: rgba(10, 34, 41, 1);
	width: 100%;
	.footer_main {
		width: 1440px;
		margin: 0 auto;
		@include alignColumn(space-between);
		.fm_top {
			width: 100%;
			padding: 40px 0 47px 0;
			border-bottom: 1px solid rgba(255, 255, 255, 0.16);
			@include alignCenter(space-between);
			.fm_title {
				color: rgba(208, 236, 245, 1);
				font-size: 26px;
				font-weight: 600;
			}
			.fm_start {
				@include alignCenter();
				> p {
					font-size: 22px;
					color: rgba(255, 255, 255, 1);
					margin-right: 50px;
				}
				> div {
					color: rgba(30, 40, 51, 1);
					text-align: center;
					font-size: 17px;
					padding: 14px 45px;
					background: rgba(208, 236, 245, 1);
				}
			}
		}

		.fm_center {
			width: 100%;
			@include alignCenter(space-between, flex-start);
			.fm_reliable {
				font-size: 22px;
				color: rgba(255, 255, 255, 1);
				margin-top: 55px;
			}
			.fm_tab {
				width: 70%;
				@include alignCenter(space-between, flex-start);
				margin-top: 40px;
				.fm_div {
					// margin-left: 135px;
					flex-shrink: 1;
					> p {
						font-size: 15px;
						color: rgba(255, 255, 255, 0.6);
						margin-bottom: 17px;
					}
					> p:nth-of-type(1) {
						font-size: 17px;
						color: rgba(208, 236, 245, 1);
					}
				}
				.fm_product,
				.fm_about {
					> p {
						white-space: nowrap;
					}
				}
				.fm_contactt {
					width: 45%;
					> p:nth-of-type(3) {
						width: 100%;
						line-height: 30px;
					}
				}
			}
		}
		.fm_footer {
			font-size: 15px;
			color: rgba(255, 255, 255, 1);
			margin: 55px 0 10px 0;
		}
	}
}
</style>
