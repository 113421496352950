/*
 * @Author: caizhihao
 * @Date: 2023-08-07 17:40:32
 * @LastEditors: caizhihao 177745994@qq.com
 * @LastEditTime: 2023-08-09 10:35:34
 * @FilePath: \PriorityPay-web\src\lang\i18n.js
 * @Description:
 *
 */
import { createI18n } from 'vue-i18n'
import EN from '../lang/en-US'
import ID from '../lang/en-ID'

const messages = {
	ID: { ...ID },
	EN: { ...EN },
}

const langType = sessionStorage.getItem('lang') || 'EN'
const i18n = createI18n({
	locale: langType,
	messages,
})
export default i18n
