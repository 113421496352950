<!--
 * @Author: caizhihao
 * @Date: 2023-08-07 17:40:32
 * @LastEditors: caizhihao 177745994@qq.com
 * @LastEditTime: 2023-08-09 16:33:14
 * @FilePath: \PriorityPay-web\src\components\web-header.vue
 * @Description: 
 * 
-->
<template>
	<header class="header">
		<section class="header_main">
			<img src="../assets/img/logo.png@2x.png" alt="" />
			<nav class="hm_title">
				<p v-for="item in routeList" :key="item.path" :style="{ color: item.path === pathStr ? '#000' : '' }" @click="handleGo(item.path)">{{ item.title }}</p>
			</nav>
			<div class="hm_language" @click="isShow = !isShow">
				<div>{{ $t('priority.language') }} {{ lang }}</div>
				<div class="hml_popover" :style="{ display: isShow ? 'block' : 'none' }">
					<div v-for="item in langList" :key="item.code" :class="[lang === item.code ? 'isLang' : '']" @click="changeLang(item.code)">{{ item.title }}</div>
				</div>
			</div>
		</section>
	</header>
</template>

<script setup>
import { getCurrentInstance, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const { proxy } = getCurrentInstance()
const isShow = ref(false)
const lang = ref('')
const pathStr = ref('')

const routeList = ref([
	{ path: 'home', title: proxy.$t('priority.home') },
	{ path: 'product', title: proxy.$t('priority.product') },
	{ path: 'about', title: proxy.$t('priority.about') },
])
const langList = ref([
	{ code: 'EN', title: 'English' },
	{ code: 'ID', title: 'Bahasa Indonesia' },
])

lang.value = sessionStorage.getItem('lang') || 'EN'

router.afterEach(to => {
	pathStr.value = to.name
})

const changeLang = langType => {
	sessionStorage.setItem('lang', langType)
	lang.value = langType
	window.location.reload()
}

const handleGo = path => {
	pathStr.value = path
	router.push({ path })
}
</script>

<style lang="scss" scoped>
.header {
	background: #fff;
	width: 100%;
	border-bottom: 1px solid rgba(15, 36, 32, 0.1);
	.header_main {
		width: 1440px;
		margin: 0 auto;
		@include alignCenter(space-between);
		> img {
			width: 118px;
			height: 118px;
		}
		.hm_title {
			font-size: 17px;
			font-weight: 700;
			color: rgba(15, 36, 32, 0.4);
			width: 30%;
			cursor: pointer;
			@include alignCenter(space-between);
		}
		.hm_language {
			font-size: 18px;
			color: rgba(0, 0, 0, 0.53);
			cursor: pointer;
			position: relative;
			z-index: 88;

			.hml_popover {
				position: absolute;
				top: 40px;
				left: 0px;
				background: rgba(255, 255, 255);
				box-shadow: 0px 2.67px 5.33px rgba(0, 0, 0, 0.25);
				border-radius: 10px;
				> div {
					white-space: nowrap;
					padding: 12px 67px 12px 16px;
					font-size: 15px;
					font-weight: 500;
					color: rgba(128, 128, 128, 1);
				}
			}
			.isLang {
				background: rgba(240, 252, 255, 1);
				color: rgba(42, 130, 228, 1) !important;
			}
		}
	}
}
</style>
